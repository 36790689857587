import { graphql, useStaticQuery } from 'gatsby';

import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from '@reach/router';

const query = graphql`
  query {
    wp {
      generalSettings {
        title
        description
      }
    }
  }
`;

const SEO = ({ seo, language }) => {
  const {
    wp: { generalSettings },
  } = useStaticQuery(query);

  const { href } = useLocation();

  // double check canonical links config
  seo.canonical = href;

  if (!seo.title || seo.title === '') {
    seo.title = generalSettings.title;
  }

  if (!seo.metaDesc || seo.metaDesc == '') {
    seo.metaDesc = generalSettings.description;
  }

  if (seo.opengraphImage || seo.twitterImage) {
    seo.image = seo.opengraphImage || seo.twitterImage;
  }

  // INTEGRATE MORE META TAGS - https://gist.github.com/lancejpollard/1978404
  // INTEGRATE JSON structured data - https://developers.google.com/search/docs/guides/intro-structured-data
  // Test structured data in development - https://search.google.com/structured-data/testing-tool/u/0/

  return (
    <Helmet>
      <html lang={language.toLowerCase()} />
      <title>{seo.title}</title>
      {seo.canonical && <link rel="canonical" href={seo.canonical} />}
      {seo.metaDesc && <meta name="description" content={seo.metaDesc} />}
      {seo.metaKeywords && <meta name="keywords" content={seo.metaKeywords} />}
      <link rel="preconnect" href={process.env.GATSBY_API_ENDPOINT} />
      <meta name="copyright" content="Copetti Antiquari" />
      <meta name="language" content={language} />
      <meta name="author" content="fabioruolo, hello@fabioruolo.com" />
      <meta name="designer" content="Andrea Saccavini, andrea.saccavini@gmail.com" />
      {/* metaRobotsNoFollow */}
      {/* metaRobotsNoindex */}
      {seo.noIndex && <meta name="robots" content="noindex" />}
      <meta property="og:type" content="website" />
      {seo.canonical && <meta property="og:url" content={seo.canonical} />}
      {/* <meta property="og:type" content="article" /> */}
      {seo.opengraphTitle && <meta property="og:title" content={seo.opengraphTitle} />}
      {seo.opengraphDescription && <meta property="og:description" content={seo.opengraphDescription} />}
      {seo.image && <meta property="og:image" content={seo.image.sourceUrl} />}
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:creator" content={post.author.twitter} /> */}
      {seo.twitterTitle && <meta name="twitter:title" content={seo.twitterTitle} />}
      {seo.twitterDescription && <meta name="twitter:description" content={seo.twitterDescription} />}
      {seo.image && <meta name="twitter:image" content={seo.image.sourceUrl} />}
    </Helmet>
  );
};

SEO.propTypes = {
  seo: PropTypes.object,
  language: PropTypes.string,
};

SEO.defaultProps = {
  language: 'EN',
};

export default SEO;
