import CustomLink from '@components/common/CustomLink';
import Grid from '@components/common/Grid';
import Image from '@components/common/Image';
import React from 'react';
import SEO from '@components/common/SEO';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import styled from 'styled-components';

function Locations({ data, pageContext }) {
  const {
    seo,
    title,
    ACFSedi: { locationsList },
  } = data.wpPage;
  const { language: lang, id } = pageContext;

  return (
    <>
      <SEO seo={seo} language={lang} />
      <Grid animKey={`page-${id}`} transition>
        {locationsList?.map(location => {
          const { longTitle, address } = location.ACFSede;
          return (
            <article key={location.id} className="col-span-12">
              <StyledCustomLink url={location.uri} className="grid grid-cols-12 gap-x-12 gap-y-12">
                <div className="col-span-12 grid grid-cols-12 gap-x-12 gap-y-12 auto-rows-max md:col-span-6">
                  {longTitle ? (
                    <h2 className="col-span-12 sm:col-span-6 md:col-span-12 lg:col-span-6">{parse(longTitle)}</h2>
                  ) : (
                    <h2>{location.title}</h2>
                  )}
                  {address && (
                    <p className="col-span-12 sm:col-span-6 md:col-span-12 lg:col-span-6">{parse(address)}</p>
                  )}
                </div>
                {location.featuredImage && (
                  <div className="col-span-12 md:col-span-6">
                    <Image image={location.featuredImage} upTo="lg2x" />
                  </div>
                )}
              </StyledCustomLink>
            </article>
          );
        })}
      </Grid>
    </>
  );
}

const StyledCustomLink = styled(CustomLink)`
  img {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
  }

  &:hover img {
    opacity: 0.75;
  }
`;

export const data = graphql`
  query Locations($id: String) {
    wpPage(id: { eq: $id }) {
      ACFSedi {
        locationsList {
          ... on WpLocation {
            id
            uri
            title
            ACFSede {
              longTitle
              address
            }
            featuredImage {
              node {
                altText
                caption
                mediaDetails {
                  width
                  height
                  sizes {
                    height
                    name
                    sourceUrl
                    width
                  }
                }
                mediaItemUrl
              }
            }
          }
        }
      }
      seo {
        ...seoFields
      }
    }
  }
`;

export default Locations;
